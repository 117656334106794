
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;600&display=swap');

// Fontawesome
@import "adminKit/fontawesome/scss/fontawesome.scss";
@import "adminKit/fontawesome/scss/regular.scss";
@import "adminKit/fontawesome/scss/solid.scss";
@import "adminKit/fontawesome/scss/brands.scss";




body {
    font-family: 'Inter', sans-serif;
    color: #868686
}

a {
    color: #BF1718;
}
a:hover {
    color: #BF1718;
    text-decoration: none;
    font-weight: bold;
}

img {
    max-width: 100%;
}

h1 {
    border-bottom: 1px solid #1C1C1B;
    color: #1C1C1B;
    font-family: "Inter", Sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;
    width: 100%;
    margin: 20px 0;
    text-align: center;
}

h1 a {
    color: #1C1C1B;
    text-decoration: none !important; 
}
h1 a:hover {
    color: #BF1718 !important;
    text-decoration: none !important;
 }

h2 {
    color: #1C1C1B;
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0;
}

.h2-bgcolor {
    margin-top: 0px;
    padding: 10px 0px 10px 25px !important;
    background-color: #EAE9E9;
}

.h2-bgcolor:hover {
    margin-top: 0px;
    padding: 10px 0px 10px 25px !important;
    background-color: #BF1718;
    color: #ffffff;
}

h3 {
    color: #1C1C1B;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    margin-top: 0.5rem;
}



h5, a h5 {
    color: #000 !important;
    font-size: 20px;
    line-height: 22px !important;
    font-family: Inter, Sans-serif !important;
    font-weight: bold;
}

h6, a h6 {
    color: #000 !important;
    font-size: 18px;
    line-height: 20px !important;
    font-family: Inter, Sans-serif !important;
    font-weight: normal;
}
a:hover h5, a:hover h6 {
    color: #BF1718 !important;
    text-decoration: none !important;
 }

 h6 small, h6 small strong {
     color: #6F6F6E;
 }

 hr {
    
    background-color: #868686;
    width: 100%;
 }



header {
    margin-bottom: 20px;
    overflow: hidden;
}



header .locale a {
    font-size: 20px;
    font-weight: normal;
    color: #868686;
}
header .locale a:hover, header .locale b {
    font-size: 20px;
    font-weight: bold;
    color: #868686;
}

header .locale .friend {
    font-size: 18px;
    font-weight: 100;
    text-decoration: none;
}
header .locale .friend:hover {
    font-size: 20px;
    text-decoration: none;
}

header .locale .friend:hover svg {
    height:100px;
    width:100px;
}



header nav a {
    font-size: 30px;
    font-weight: normal;
    color: #868686;
}

header nav a:hover {
    font-size: 30px;
    font-weight: bold;
    color: #1C1C1B;
    text-decoration: none;
}





header .menu-search {
    margin-top: 16px;
    color: #868686;
}

header .menu {
    clear: right;
}

header .menu ul {
    margin-bottom: 0px;
    margin-top: 20px;
}

header .menu .menu-item {
    width: 194px;
}

header .menu .sub-menu {
    display: none;
}

header .menu .menu-item:hover .sub-menu {
    display: flex !important; 
}

header .menu .sub-menu {

    /*transition: background 200ms linear, color 200ms linear, border 200ms linear;*/

    margin-top: 0px !important;
    z-index: 999;
    background: #f1f1f1;
    
    position: absolute;
    
    line-height: 60px;

    width: 100%;
    left: 0;

    justify-content: center;
    
    /*
    border: 0;
    padding: 0px 0px 0px 0px;
    
    
    max-width: none;
    
    */
}


header .menu .sub-menu a {
    font-size: 18px;
    font-weight: normal;
    color: #1C1C1B;
}

header .menu .sub-menu a:hover {
    font-weight: bold;
}


header .menu .sub-menu .sub-menu-item {
    padding: 0px 20px;
}


header .bi-search:hover {
    cursor: pointer;
}

.banner {
	height: 400px;
	overflow:hidden;
}

.banner .carousel-img {
	width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.banner .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}





footer {
    margin-top: 20px;
    background-color: #868686;
    color: #ffffff;
    font-size: 11px;
}

footer a {
    color: #ffffff !important;
}

footer h5 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
}

footer address {
    margin-bottom: 0px;
}

footer .form-control {
    margin-bottom: 10px;
    border-color: #ffffff;
    border-radius: 0;
    background-color: #868686;
    color: #ffffff;
    font-size: 12px;
}
footer .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cdcdcd;
    opacity: 1; /* Firefox */
  }
  
footer .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cdcdcd
  }
  
footer .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #cdcdcd
  }

footer .btn-primary {
    margin-bottom: 10px;
    border-color: #ffffff;
    border-radius: 0;
    background-color: #868686;
    color: #ffffff;
    font-size: 12px;
    width: 140px;
    text-align: center;

}
footer .btn-primary:hover {
    background-color: #BF1718;
    border-color: #BF1718;
}


footer .social li {
	/*display:inline-block;
	border:2px solid #fff;
	border-radius:15px;*/
}

footer .social li a {
	/*display: block;
	color: white;
	text-align: center;
	padding: 5px;
	text-decoration: none;
	margin:0 10px;*/
}





section p {
    margin-bottom: 0px !important;
}

section .dates {
    color: #6F6F6E !important;
    font-size: 18px;
    line-height: 28px !important;
    margin-top: 15px;
}

section .author {
    color: #1C1C1B !important;
    font-size: 24px;
    line-height: 24px !important;
    margin-top: 6px;
    font-weight: bold;
}

section .title {
    color: #1C1C1B !important;
    font-size: 24px;
    line-height: 29px !important;
    font-style: italic;
}



/**
 *  BLOG: listado de elementos del Menú
 */
section .list-menu-items {
    margin: 0;
}

section .list-menu-items li {
    margin-top: 10px;
    padding: 10px 0px 10px 25px !important;
    background-color: #EAE9E9;
}

section .list-menu-items a {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: #1C1C1B; 
}

section .list-menu-items a:hover li {
    background-color: #BF1718;
    color: #FFFFFF; 
}

/**
 *  Breadcrumbs
 */

 .breadcrumbs a {
    color: #636262 !important;
    text-decoration: none !important;
	opacity:0.6;
 }

 .breadcrumbs .active a {
    font-weight: bold;
	opacity:0.6;
 }

 .breadcrumbs a:hover {
    color: #BF1718 !important;
    font-weight: bold;
    text-decoration: none !important;
	opacity:0.6;
 }

 /**
  *
  */

  article {
    font-family: "Inter", Sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
  }

  article strong {
    color: #404040;
  }

  article .img {
    color: #404040;
    font-family: "Inter", Sans-serif;
    font-size: 21px;
    font-weight: normal;
    line-height: 36px;
  }

  .foundation-structure {
    text-align: center;
    background-color: #EAE9E9;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .foundation-structure strong {
    //display: inline-block;
    //width: 100%;
    font-size: 20px;
    line-height: 21px;
    color: #333;
  }

  .foundation-structure span {
    //display: inline-block;
    //width: 100%;
    font-size: 18px;
    line-height: 28px;
    color: #333;
  }

  .foundation-structure small {
    color: #333333;
    font-family: "Inter", Sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 10px;
  }

  .image-thumbnail {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.overlaycoleccio {
    height: 60px !important;
}

.overlayartistes, .overlaycoleccio {
    position: absolute;
    bottom: 0%;
    left: 0;
    right: 0;
    height: 20%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: white;
    width: 100%;
}

.textcoleccio {
    font-size: 17px !important;
    line-height: 21px !important;
}

.textartistes, .textcoleccio {
    color: #636262 !important;
    font-family: Inter, Sans-serif !important;
    font-size: 21px;
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}



.escrits .dataescrits {
    color: #4D4D4D !important;
    font-size: 19px;
    line-height: 26px !important;
    font-family: Inter, Sans-serif !important;
    font-weight: normal;
    margin-bottom: 15px !important;
}

.escrits .titolescrits, .escrits a{
    color: #000 !important;
    font-size: 22px;
    line-height: 26px !important;
    font-family: Inter, Sans-serif !important;
    font-weight: bold;
}

.escrits a:hover{
    color: #BF1718 !important;
}

.escrits .personaillocescrits {
    color: #000 !important;
    font-size: 22px;
    line-height: 24px !important;
    font-family: Inter, Sans-serif !important;
    font-weight: normal;
}

.readmore {
    font-size: 20px;
}


.video {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 25px;
	height: 0;
}
.video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.entry-thumbnail {
    width: 100%;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-list {
    width: 100%;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-2 {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-3 {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-4 {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-200 {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail-400 {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}

.thumbnail:hover {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
}


/* KNP Paginator */
.navigation ul {
    justify-content: center !important;
}

.navigation .page-item.active .page-link {
    background-color: #BF1718;
    border-color: #BF1718;
}

.navigation .page-link {
    color: #BF1718;
}



.carousel-caption {
    background-color: #fff;
    opacity: 0.8;
}







